
$screen-md: 768px;

.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.swiper-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;

  @media (min-width: $screen-md) {
    justify-content: space-around;
    align-items: center;
  }
}

.swiper-slide {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $screen-md) {
    flex: none;
    display: flex;
    justify-content: center;
    
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  @media (min-width: $screen-md) {
    display: none;
  }

}

.pagination-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  margin: 0 4px;
  cursor: pointer;
}

.pagination-dot.active {
  background-color: $yellow;
}
