
.verify-account {
  position: relative;

  .verification-alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    i {
      font-size: 30px;
      padding: 0 5px;

      &.qi-i-remove {
        font-size: 40px;
        position: absolute;
        cursor: pointer;

        body[dir='ltr'] & {
          right: 0;
        }

        body[dir='rtl'] & {
          left: 0;
        }
      }
    }

    span {
      font-size: 14px;
    }
  }

  .verify-account__card {
    max-width: 500px;
    margin: 0 auto;
    border-radius: 50px 10px 50px 10px;
  }

  .resend-code {
    .btn-secondary.btn-link,
    .btn-secondary.btn-link:hover,
    .btn-secondary.btn-link:active,
    .btn-secondary.btn-link:focus,
    .btn-secondary.btn-link:not(:disabled):not(.disabled):active {
      background-color: transparent;
      color: $qurrah-light-blue;
      text-transform: none;
      border: none;
      box-shadow: none;
    }
  }
}
