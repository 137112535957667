
.survey-modal{
    line-height: 1.8em;
    ul{
        @include coloredBulletSquare($primary);
        font-size: 0.95em;
        
        li::before{
            top: 9px;
        }
    }
}