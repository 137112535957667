

$screen-xs: 720px;

.service__label-placeholder {
  text-transform: uppercase;
  font-size: 16px;
}

.service__alert {
  position: sticky !important;
  top: 0;
  z-index: $zindex-sticky;
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $screen-xs) {
    flex-direction: column;

    & button {
      margin-top: 20px;
      width: 100%;
      justify-content: center !important;
    }
  }
}
