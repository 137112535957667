.q-pin {
  height: 35px;
  width: 25px;
  cursor: pointer;
}
.marker-window {
  & .card {
    transition: all 0.3s ease-in-out;

    & .card-body {
      padding: 0;
      position: relative;
      .col {
        padding: 0;
      }
      & .card-title {
        font-size: 17px;
        font-weight: bold;
        color: #172b4d;
        margin-bottom: 0.5rem;
        overflow: visible;
      }
    }

    & .card-img-container {
      position: relative;
      margin: 0;
      // height: 100px;
      overflow: hidden;

      img {
        max-height: 200px;
      }
    }
  }
}
