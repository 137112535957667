
.services__card {
  & .card {
    border-radius: 60px 10px;
    box-shadow: $box-shadow-lg;
    padding: 6%;
    margin-bottom: 32px;
    transition: all 0.3s ease-in-out;

    & .card-body {
      padding: 1.2rem;
      position: relative;
      text-align: center;

      & .card-title {
        font-size: 28px;
        font-weight: bold;
        color: #172b4d;
        margin-bottom: 1rem;
      }

      & .card-text {
        color: #56606b;
        margin-bottom: 0;
        font-size: 12px;
      }
    }

    & figure img {
      height: 64px;
    }

    &:hover {
      box-shadow: $box-shadow;
      cursor: pointer;
    }
  }
}

.services__badge {
  background-color: $white;
  font-size: 0.75rem;
  color: $orange;
  border: 1px solid $orange;
}

//TODO extend styles for other statuses if needed
.services__badge--published {
  @extend .services__badge;
  color: $green;
  border: 1px solid $green;
}

.services__badge--unpublished {
  @extend .services__badge;
  color: $red;
  border: 1px solid $red;
}

.services__badge--draft {
  @extend .services__badge;
  color: $gray-300;
  border: 1px solid $gray-300;
}

.btn__icon {
  font-size: 1.25rem;
  padding-right: 8px;
  margin: 0;

  body[dir='rtl'] & {
    padding-right: 0;
    padding-left: 8px;
  }
}
.services-info {
  font-size: 15px;

  i {
    color: $qurrah-light-blue;
    font-weight: 800;
    vertical-align: middle;
  }
}
