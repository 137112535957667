
.service-info-card {
  & .card-title {
    font-weight: bold;
    font-size: 24px;
  }

  & .card-body {
    padding: 2.5rem 2rem;
  }
}

.working-hours-list {
  list-style: none;

  & li {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  & i {
    color: #f16f70;
    font-weight: bold;
  }
}

.curriculum-list {
  list-style-type: none;
  padding-left: 0;

  & li {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      font-size: 25px;
      font-weight: 700;
      color: $primary;
    }
  }

  body[dir='rtl'] & {
    padding-left: auto;
    padding-right: 0;
  }
}

.facilities-list {
  list-style-type: none;
  padding-left: 0;

  & li {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & span:first-of-type {
      padding: 0 60px 0 0;
      display: inline-flex;

      body[dir='rtl'] & {
        padding: 0 0 0 60px;
      }
    }

    i {
      font-size: 25px;
      font-weight: 700;
      color: $primary;
    }
  }

  body[dir='rtl'] & {
    padding-left: auto;
    padding-right: 0;
  }
}

.branch-service {
  .service-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .service-label {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      color: $primary;
      font-size: 25px;

      body[dir='ltr'] & {
        padding-right: 8px;
      }

      body[dir='rtl'] & {
        padding-left: 8px;
      }
    }

    & + p {
      padding-left: 32px;

      body[dir='rtl'] & {
        padding-left: 0;
        padding-right: 32px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .branch-service {
    background: linear-gradient(45deg, rgba(246, 241, 234, 1) 0%, rgba(246, 241, 234, 1) 100%);
    border-radius: 150px 0 0 0;
    background-position: 0 260px;
    background-repeat: no-repeat;
    background-clip: content-box;
  }

  .service-info-container {
    position: sticky !important;
    top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .branch-service {
    background: rgba(246, 241, 234, 1);

    & .service-header {
      flex-direction: column;
    }
  }

  .service-info-card {
    // background: linear-gradient(45deg, #62adc4 0%, #173057 100%);
    // color: #fff !important;
    position: fixed !important;
    bottom: 0;
    left: 0;
    border-radius: 0 !important;
    width: 100vw;
    z-index: 99;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);

    & .card-body {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 20px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

      & .card-title {
        font-size: 16px;
        margin-bottom: 0;
      }

      & .text-container {
        padding: 0 10px 0 0;

        body[dir='rtl'] & {
          padding: 0 0 0 10px;
        }
      }
    }

    & button {
      max-width: 150px;
    }
  }
}
