.support-iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100%;
  // margin-bottom: 200px;
}
.support-info {
  .bg-beige {
    background: #f6f1ea !important;
  }
  h5 {
    color: #172b4d;
  }
}
