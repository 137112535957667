@import 'styles/variables.scss';

$button-size: 1rem;
$button-cross-size: 0.6;
$button-color: transparent;
$button-border-width: 2px;
$button-border-color: $gray-dark-2;
$cross-color: $gray-dark;

.qurrah-dialog-backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    z-index: 999;

    .qurrah-dialog-container {
        position: relative;

        max-width: 42rem;

        background: rgba(255, 255, 255, 0.986);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 15px 40px rgba(0, 0, 0, 0.1);

        border-radius: 30px 4px;

        display: flex;
        flex-direction: column;
        padding: 3rem 0.3rem 1rem 3rem;


        .title {
            text-align: center;
        }

        .close-button {
            all: unset;
            position: absolute;
            
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            width: $button-size;
            height: $button-size;

            background-color: $button-color;
            border-radius: 50%;
            border: $button-border-width solid $button-border-color;
            transition: background-color 0.3s;

            top: 1.5rem;
            
            body[dir="rtl"] & {
                right: 1.3rem;
                left: auto;
            }

            body[dir='ltr'] & {
                left: 1.3rem;
                right: auto;
            }

            &:focus {
                outline: none;
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                width: $button-size * $button-cross-size;
                height: $button-border-width;
            }

            &::before {
                transform: rotate(45deg);
                background-color: $cross-color;
            }

            &::after {
                transform: rotate(-45deg);
                background-color: $cross-color;
            }
        }

        .dialog-backdrop-body{
            padding-right: 30px;
        }
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media screen and (max-width: 767px) {
    .qurrah-dialog-backdrop{
        .qurrah-dialog-container {
            margin: 0 20px;
            max-width: 100%;
            padding-left: 0;
            .dialog-backdrop-body{
                padding: 1.2rem;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .qurrah-dialog-backdrop{
        .qurrah-dialog-container {
            margin: 0 10px;
            max-width: 100%;
        }
    }
  }