

.input__addon {
  .input-group-text {
    border: 1px solid $gray-400 !important;
    color: $gray-700;
  }
}

.date__append {
  position: absolute;
  right: 0;
  height: 100%;

  .input-group-text {
    border: none;
    transform: scale(0.9);
  }

  body[dir='rtl'] & {
    left: 0;
    right: auto;
  }
}

.info__wrapper {
  display: flex;
  align-items: center;
}

.info__icon {
  color: $qurrah-light-blue;
  font-size: 1rem;
}

.custom-radio {
  cursor: pointer;
  margin: 0 10px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $white;
    border: 1px solid $gray-400;
    position: absolute;
    top: 0;

    //test
    top: 50%;
    transform: translateY(-50%);

    body[dir='ltr'] & {
      left: 0;
    }

    body[dir='rtl'] & {
      right: 0;
    }
  }

  &:checked {
    &::before {
      border-color: $primary;
      border-width: 5px;
    }

    &:disabled {
      &::before {
        border-color: $gray-400;
        border-width: 5px;
      }
    }
  }
}

.radio {
  display: flex;
  align-items: center;

  .label--disabled {
    color: $gray-400;
  }
}

.inactive-field__label {
  font-size: 0.75rem;
  color: $gray-800;
  font-weight: $font-weight-bold;
  margin-bottom: 0.5rem;
}

.inactive-field__label--text,
.inactive-field__label--number {
  @extend .inactive-field__label;
  text-transform: uppercase;
}

.inactive-field__value {
  font-size: 0.875rem;
  color: $gray-700;
}

.inactive-field__value--text,
.inactive-field__value--number {
  @extend .inactive-field__value;
  font-weight: $font-weight-normal;
  margin-top: 8px;
  padding: $input-btn-padding-y 0;
  text-transform: capitalize;
}

.inactive-field__label--checkbox {
  @extend .inactive-field__value;
  padding: 0;
}

.inactive-field__icon {
  color: $primary;
  font-size: $icon-size-xs;
  font-weight: $font-weight-extra-bold;
  padding: 0 8px 0 2px;
  margin: 0;

  body[dir='rtl'] & {
    padding: 0 2px 0 8px;
  }
}

.inactive-field__icon--small {
  @extend .inactive-field__icon;
  font-size: 0.5rem;
}

body[dir='rtl'] .qi-c-question-2 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
