
$screen-md: 768px;

.objective-title {
  @include coloredSquares($green, $yellow);
  color: $blue;
  padding: 1rem 0;
  display: inline-block;
}

.objectives-item{
  @media (min-width: $screen-md) {
    width: 500px;
  }
}

.qurrah-objectives img{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}