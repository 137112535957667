/* Form controls */

label {
  font-size: $font-size-sm;
  text-transform: uppercase;
  color: $lead-font-color;
  font-weight: $font-weight-bold;
}

.form-control {
  font-size: $input-btn-font-size;
  transition: $transition-base;

  &:focus {
    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }

  &.is-valid,
  &.is-invalid {
    background-image: none;
  }
}

/* Textareas */

textarea[resize='none'] {
  resize: none !important;
}

textarea[resize='both'] {
  resize: both !important;
}

textarea[resize='vertical'] {
  resize: vertical !important;
}

textarea[resize='horizontal'] {
  resize: horizontal !important;
}

/* Alternative styles */

.form-control-muted {
  background-color: $input-muted-bg;
  border-color: $input-muted-bg;
  box-shadow: none;

  &:focus {
    background-color: $input-muted-bg;
  }
}

.form-control-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow 0.15s ease;

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

// Sizes

.form-control-lg {
  font-size: $font-size-base;
}

// Validation

.has-success,
.has-danger {
  position: relative;

  &:after {
    width: 19px;
    height: 19px;
    line-height: 19px;
    text-align: center;
    font-family: 'NucleoIcons';
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 2px;
    transform: translateY(50%);
    border-radius: 50%;
    font-size: 9px;
    opacity: 1;
  }

  .form-control-alternative {
    &.is-valid,
    &.is-invalid {
      &:focus {
        box-shadow: $input-focus-alternative-box-shadow !important;
      }
    }
  }
}

.has-success {
  &:after {
    content: '\ea26';
    color: darken($form-feedback-valid-color, 18%);
    background-color: $form-feedback-valid-bg;
  }
  .form-control {
    background-color: $input-focus-bg;

    &:focus {
      border-color: $input-focus-border-color;
    }

    // Placeholder
    &::placeholder {
      color: $form-feedback-valid-color;
    }
  }
}

.has-danger {
  &:after {
    content: '\ea53';
    color: darken($form-feedback-invalid-color, 18%);
    background-color: $form-feedback-invalid-bg;
  }
  .form-control {
    background-color: $input-focus-bg;

    &:focus {
      border-color: $input-focus-border-color;
    }

    // Placeholder
    &::placeholder {
      color: $form-feedback-invalid-color;
    }
  }
}

.form-bg {
  border-radius: 30px 5px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 15px 40px rgba(0, 0, 0, 0.1);
  padding: 35px !important;
}

.form-bg:has(.soft-corners){
  border-radius: 10px !important;
}

.form-wizard {
  @extend .form-bg;
  border-radius: 4px;
}

@media screen and (min-width: 992px) {
  .form-bg {
    border-radius: 60px 10px;
    padding: 50px !important;
  }

  .form-wizard {
    border-radius: 4px;
    padding: 50px !important;
  }
}

// RTL styles for Forms

body[dir='rtl'] .custom-select {
  background: str-replace(
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
      '#',
      '%23'
    )
    no-repeat left 0.75rem center/8px 10px;
  background-color: #fff;
}

body[dir='rtl'] .input-group-prepend {
  margin-left: -$input-border-width;
  margin-right: initial;
}

body[dir='rtl'] .input-group-append {
  margin-left: -$input-border-width;
  margin-right: initial;
}

body[dir='rtl'] .input-group .form-control:not(:last-child) {
  border-right: 1px solid #ced4da;
  padding-right: 12px;
  padding-left: 0;
  border-left: 0;
}

body[dir='rtl'] .input-group > .form-control:not(:last-child),
body[dir='rtl'] .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

body[dir='rtl'] .input-group > .input-group-append > .btn,
body[dir='rtl'] .input-group > .input-group-append > .input-group-text {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
