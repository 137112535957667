$screen-lg: 992px;

footer {
    .re-edited-box-icon{
        background-color: #2e2e2e;
        max-width: 200px;
        img{
            display: inline-block;
            max-width: 100%;
        }
    }

    &.is-hrdf{
        background-image: none !important;
        .navbar-logo-hrdf{
            height: 80px !important;
            width: auto !important;
            aspect-ratio: 2/1 !important;
        }
        .footer-hrdf-logo{
            height: 80px !important;
            width: 80px !important;
            aspect-ratio: 1/1 !important;
        }
      }
    @media screen and (min-width: $screen-lg) {
        .working-hours-details{
            max-width: 300px;
        }
      }
    .dga-footer{
        img{
            width: 120px;
        }
    }
}