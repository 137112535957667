
.branches__card{
    & .card{
        transition: all 0.3s ease-in-out;

        & .card-body {
            padding: 1.2rem;
            position: relative;
            text-align: center;
    
            & .card-title {
                font-size: 24px;
                font-weight: bold;
                color: #172b4d;
                margin-bottom: 1rem;
            }

            & .card-subtitle {
                font-size: 18px;
                margin-bottom: 1rem;
            }
    
            & .card-text{
                color: #56606B;
                margin-bottom: 0;
                font-size: 12px;
            }

        }

        & figure img {
            height: 64px;
        }

        &:hover {
            background: #F6F1EA;
            box-shadow: 0 5px 15px rgba(0,0,0,0.1);
            transition: all 0.3s ease-in-out;
        }
        
        
    }
    
}

.branch-icon {
  color: $primary;
  font-size: 40px;
}