
.qurrah-badge {
  border: 1px solid;
  border-radius: 20px;
  padding: 3px 10px;
  border-color: $default;
  color: $default;
  display: inline-block;

  &.qurrah-badge--primary {
    border-color: $primary;
    color: $primary;
  }

  &.qurrah-badge--secondary {
    border-color: $secondary;
    color: $secondary;
  }

  &.qurrah-badge--success {
    border-color: $success;
    color: $success;
  }

  &.qurrah-badge--info {
    border-color: $info;
    color: $info;
  }

  &.qurrah-badge--warning {
    border-color: $warning;
    color: $warning;
  }

  &.qurrah-badge--danger {
    border-color: $danger;
    color: $danger;
  }

  &.qurrah-badge--light {
    border-color: $light;
    color: $light;
  }

  &.qurrah-badge--dark {
    border-color: $dark;
    color: $dark;
  }

  &.qurrah-badge--square {
    border-radius: 4px;
  }

  &.qurrah-badge--full {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    float: right;

    body[dir='rtl'] & {
      float: left;
    }
  }

  &.qurrah-badge--upper {
    text-transform: uppercase;
  }

  &.qurrah-badge--icon {
    i {
      padding: 0 5px 0 0;
      font-weight: 700;

      body[dir='rtl'] & {
        padding: 0 0 0 5px;
      }
    }
  }
}
