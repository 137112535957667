

.alert__custom {
  color: $white !important;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-width: -webkit-fill-available;
  .close {
    right: 1.5em;
    left: auto;
    body[dir='rtl'] & {
      right: auto;
      left: 1.5rem;
    }
  }
}

.alert-danger {
  background-color: $red !important;
}

.alert__icon {
  font-size: 1.25rem;
  font-weight: $font-weight-bold;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: middle;

  body[dir='rtl'] & {
    margin-right: 0;
    margin-left: 1rem;
  }
}

.alert__message {
  font-size: 0.95rem;
  line-height: 0.95rem;
}

.alert__elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .alert__elements {
    flex-direction: column;
  }

  .alert__message {
    line-height: initial;
  }

  .alert__button {
    margin-top: 10px;
  }
}
