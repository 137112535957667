

.error__heading {
  font-size: 2rem;
  color: $qurrah-dark-blue;
}

.error__subheading {
  font-size: $font-size-sm;
  text-align: center;
}
