.status-REJECTED_HRDF {
  background-color: rgb(227, 122, 30);
  border-radius: 50px;
  color: white !important;
  padding: 0px 15px;
}
.status-UNDER_REVIEW {
  background-color: rgb(249, 222, 118);
  border-radius: 50px;
  color: white !important;
  padding: 0px 15px;
}
.status-PENDING {
  background-color: rgb(179, 118, 249);
  border-radius: 50px;
  color: white !important;
  padding: 0px 15px;
}
.status-NOT_ELIGIBLE {
  background-color: rgb(249, 118, 186);
  border-radius: 50px;
  color: white !important;
  padding: 0px 15px;
}
.status-CLOSED {
  background-color: red;
  border-radius: 50px;
  color: white !important;
  padding: 0px 15px;
}
.child {
  h3 {
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;

    .qi-down-arrow {
      font-weight: 700;
      color: $secondary;
      padding: 0 10px;
    }
  }

  h6 {
    text-transform: uppercase;
    font-size: 13px;
    padding-top: 0;
  }

  .card-title {
    border-bottom: 1px solid $gray-400;
  }

  .info-row > div {
    padding: 12px 15px;
  }

  .edit-child {
    padding: 14px 40px;
    float: right;
    margin-top: -70px;

    body[dir='rtl'] & {
      float: left;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      width: 100%;
      margin: 20px 0;
    }
  }

  .required-documents {
    &__item {
      padding: 10px 0;
    }

    h6 {
      padding-top: 0;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        margin-bottom: 8px;
      }
    }

    .qi-data-download {
      font-weight: 700;
      padding: 0 5px 0 0;

      body[dir='rtl'] & {
        padding: 0 0 0 5px;
      }
    }
  }

  .child-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .user-img {
      border-radius: 50%;
      overflow: hidden;
      width: 100px;
      height: 100px;
      margin-right: 20px;

      body[dir='rtl'] & {
        margin-right: 0;
        margin-left: 20px;
      }

      .child-img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;

      .user-img {
        margin: 0 !important;
      }
    }
  }
}

.back-link {
  padding-top: 5px;
  padding-bottom: 5px;

  &:before {
    content: '\eb07';
    padding-right: 5px;
    font-weight: bold;
    font-family: 'Qurrah', sans-serif;
    display: inline-block;
    font-size: 12px;

    body[dir='rtl'] & {
      content: '\eb08';
      padding-right: 0;
      padding-left: 5px;
    }
  }
}
