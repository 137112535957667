.form-bg {
  border-radius: 30px 5px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 15px 40px rgba(0, 0, 0, 0.1);
  padding: 35px !important;
}

@media screen and (min-width: 992px) {
  .form-bg {
    border-radius: 60px 10px;
    padding: 50px !important;
  }
}
