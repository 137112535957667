

.edit-child {
  h3 {
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;

    .qi-down-arrow {
      font-weight: 700;
      color: $secondary;
      padding: 0 10px;
    }
  }

  h6 {
    text-transform: uppercase;
    font-size: 13px;
  }

  .card-title {
    border-bottom: 1px solid $gray-400;
  }

  .divider {
    margin: 10px auto;
    width: 100%;
  }

  .cancel-btn {
    float: left;
    margin: 20px 0 40px;

    body[dir='rtl'] & {
      float: right;
    }
  }

  .submit-btn {
    float: right;
    margin: 20px 0 40px;

    body[dir='rtl'] & {
      float: left;
    }
  }

  .emergency-contacts {
    .contact-btn {
      color: $gray-600;
      background: transparent;
      border: none;
      box-shadow: none;

      &:hover,
      &:focus,
      &.btn-secondary:active {
        color: $yellow;
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }

  .required-documents {
    & .form-group {
      margin-bottom: 0;
    }

    h6 {
      padding-top: 0;
      margin-bottom: 8px;

      @media screen and (max-width: 767px) {
        margin-bottom: 8px;
      }
    }

    label {
      display: none !important;
    }
  }

  .child-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .user-img {
      border-radius: 50%;
      overflow: hidden;
      width: 100px;
      height: 100px;
      margin-right: 20px;

      body[dir='rtl'] & {
        margin-right: 0;
        margin-left: 20px;
      }

      .child-img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-top: 120px;

      .user-img {
        margin: 0 !important;
      }
    }
  }
}

.upload-btn-icon {
  margin-right: 8px;

  body[dir='rtl'] & {
    margin-left: 8px;
    margin-right: 0;
  }
}
