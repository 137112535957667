

.link-arrow {
  color: $red;
  font-size: 22px;
}

.arrows-sort, .arrows-sort--down, .arrows-sort--up {
  padding: 5px 8px;
  cursor: pointer;
}

.arrows-sort {
  font-size: 15px;
}

.arrows-sort--down, .arrows-sort--up {
  font-size: 10px;
  font-weight: 700;
}
