

h6 {
  padding-top: 20px;
  text-transform: uppercase;
}

.parent-booking {
  h5 {
    font-size: 25px;
    border-bottom: 1px solid $gray-400;
    margin-bottom: 20px;
  }

  .booking-status {
    border: 1px solid $primary;
    border-radius: 20px;
    display: inline-block;
    padding: 2px 25px;
    color: $primary;
  }

  .booking-status--COMPLETED,
  .booking-status--CONFIRMED,
  .booking-status--ACCEPTED {
    border: 1px solid $green;
    color: $green;
  }

  .booking-status--REJECTED,
  .booking-status--CANCELLED {
    border: 1px solid $red;
    color: $red;
  }

  .booking-status--PENDING {
    border: 1px solid $orange;
    color: $orange;
  }

  .required-documents {
    &__item {
      padding: 10px 0;
    }

    h6 {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        margin-bottom: 8px;
      }
    }
  }

  .qi-data-download {
    font-weight: 700;
    padding: 0 5px 0 0;

    body[dir='rtl'] & {
      padding: 0 0 0 5px;
    }
  }
}
