//Qurrah Intro Section
.qurrah-intro-container {
  background: #f6f1ea;
  border-radius: 0 0 0 180px;
}

//Qurrah Objectives Section
.qurrah-objectives {
  & img {
    height: 180px;
    align-self: center;
  }
}

//Mobile Promo Section
.mobile-promo-container {
  background: #f6f1ea;
  border-radius: 180px 0 0 0;
  text-align: left;

  body[dir='rtl'] & {
    text-align: right;
  }

  .mobile-promo-graphic {
    display: flex;
    justify-content: center;
  }

  .mobile-promo-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    img:first-of-type {
      margin-right: 10px;

      body[dir='rtl'] & {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .mobile-promo-container * {
    text-align: center;
  }
}

//Centers Section
.centers-section {
  color: #fff;
  background: url('/assets/images/home-center-bg@2x.avif');
  background-size: cover;

  & h2,
  h3 {
    color: #fff;
  }

  & .lead {
    color: #fff;
  }

  &:before {
    content: '';
    background: linear-gradient(120deg, rgba(98, 173, 196, 1) 0%, rgba(23, 48, 87, 1) 45%);
    background: linear-gradient(120deg, rgba(23, 48, 87, 1) 0%, rgba(51, 97, 111, 1) 75%);
    opacity: 0.84;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__content ul li img {
    align-self: start;
    height: 48px;
  }
}

@media screen and (min-width: 768px) {
  body[dir='rtl'] .offset-md-1 {
    margin-right: 8.333333% !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .centers-section {
    background-position: 65%;

    & .btn-qurrah {
      width: 100%;
      margin-bottom: 4rem !important;
    }
  }
}
