
.breadcrumb {
  background-color: transparent !important;
  padding: 0 !important;
}

.branch {
  & .card {
    & .card-img-top {
      border-radius: 50px 4px 0 0;

      body[dir='rtl'] & {
        border-radius: 4px 50px 0 0;
      }
    }
  }
  & .reset-link {
    color: $primary;
  }
  & .service-card {
    transition: all 0.3s ease-in-out;
    margin: 10px;

    & .card-title {
      color: $gray-900;
      margin: 8px 0px;
    }

    & .card-subtitle {
      color: $gray-700;
      font-size: 14px;
      margin: 8px 0px;
    }
    & .card-text {
      color: $gray-700;
      font-size: 12px;
      margin: 8px 0px;
    }

    & .service-card__price {
      color: $primary;
    }
  }

  & .service-card:hover {
    box-shadow: 0 5px 15px rgba($black, 0.1);
    transition: all 0.3s ease-in-out;
  }

  & .location-map {
    max-height: 300px;
    overflow: hidden;

    & img {
      width: 100%;
    }
  }

  .branch-meta {
    display: flex;

    i {
      color: $primary;
      font-size: 25px;
    }

    label {
      font-size: $font-size-base;
    }
  }

  .branch-rating {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      color: $secondary;
      font-size: 20px;

      & + span {
        margin: 0 10px;
      }
    }
  }

  .accreditation-logo {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid #fabc68;
    padding: 5px 8px;
    border-radius: 2px 10px;

    img {
      width: 40px;
    }
  }
}

.branch-info-card {
  & figure {
    margin: 0;
    position: relative;

    & a.btn-qurrah {
      position: absolute;
      bottom: 20px;
      right: 20px;

      body[dir='rtl'] & {
        left: 20px;
        right: auto;
      }
    }
  }

  & .card-title {
    font-weight: bold;
    font-size: 18px;
  }

  i {
    color: $primary;
    font-size: 18px;
    margin: 0 5px;
    font-weight: bold;
    vertical-align: middle;
  }

  .map-links{
    gap: 15px;
  }
}

@media screen and (min-width: 768px) {
  .branch {
    background: linear-gradient(45deg, rgba(246, 241, 234, 1) 0%, rgba(246, 241, 234, 1) 100%);
    background-position: 0 480px;
    background-repeat: no-repeat;
    background-clip: content-box;
  }

  .branch-info-card {
    position: sticky !important;
    top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .branch {
    background: rgba(246, 241, 234, 1);
  }

  .branch-info-card {
    position: fixed !important;
    bottom: 0;
    left: 0;
    border-radius: 0 !important;
    width: 100vw;
    z-index: 99;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.2);

    & .card-img-container {
      display: none;
    }

    & img {
      display: none;
    }

    & .card-body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
    .map-links{
      justify-content: center;
      gap:20px !important;
    }
    & a:not(.map-link),
    .card-title {
      display: none !important;
    }

    & .card-text {
      & i,
      & span {
        display: flex;
        width: 100%;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .mobile-photo-container {
    margin: 0;
    position: relative;
    max-height: 200px;
    overflow: hidden;

    &:before {
      content: '';
      background: #172b4d;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: block;
      top: 0;
      left: 0;
      opacity: 0.1;
      transition: all 0.3s ease-in-out;
    }

    & a.btn-qurrah {
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 9;

      body[dir='rtl'] & {
        left: 20px;
        right: auto;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .branch-info-card {
    & .card-text {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 20px;
      margin-bottom: 15px !important;

      & i,
      & span {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
}

.phone-number span {
  direction: ltr;
  display: inline-block;
}

.phone-number {
  direction: ltr;
}

// .card-img-container {
//   .card-img-top {
//     height: 40vh;
//     object-fit: cover;
//   }
// }
.branch-info-card .card-img-container {
  height: 180px;
  overflow: hidden;

  .card-img-top {
    min-height: 180px;
  }

  &:before {
    content: '';
    background: #172b4d;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    opacity: 0.1;
    border-radius: 50px 4px 0 0;
    transition: all 0.3s ease-in-out;

    body[dir='rtl'] & {
      border-radius: 4px 50px 0 0;
    }
  }
}

.show-photo-btn {
  z-index: 999;
}
