

.breadcrumb__icon {
  color: $qurrah-light-blue;
  padding-right: 8px;

  body[dir='rtl'] & {
    padding-right: 0;
    padding-left: 8px;
  }
}

.breadcrumb button {
  text-transform: initial;
  padding: 0;

  &:active {
    box-shadow: none !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}
