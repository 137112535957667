
$screen-xs: 720px;

.branch-details {
  .branch-details-icon {
    font-size: 90px;
    padding-top: 5px;

    body[dir='ltr'] & {
      padding-right: 30px;
    }

    body[dir='rtl'] & {
      padding-left: 30px;
    }
  }

  .breadcrumb {
    i {
      font-size: 10px;
      font-weight: 700;

      &.qi-left-arrow {
        padding-right: 5px;
      }

      &.qi-right-arrow {
        padding-left: 5px;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .header__branch-details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .header__branch-edit-btn {
      i {
        font-weight: 700;
        padding: 0 5px 0 0;

        body[dir='rtl'] & {
          padding: 0 0 0 5px;
        }
      }
    }

    @media (max-width: $screen-xs) {
      flex-direction: column;

      & .branch-details-icon {
        padding: 0 !important;
        font-size: 48px;
      }

      & .header__branch-details {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      & .header__branch-edit {
        width: 100%;
        margin-top: 20px;

        & .header__branch-edit-btn {
          width: 100%;
        }
      }
    }
  }

  .branch-details__card-header {
    background: $white;
  }

  .section-header {
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0;

    i {
      color: $primary;
      font-weight: 700;
      font-size: 40px;
      position: absolute;
      top: -3px;

      body[dir='ltr'] & {
        left: -7px;
      }

      body[dir='rtl'] & {
        right: -7px;
      }
    }
  }

  &.edit-branch {
    padding-top: 130px;
  }

  @media screen and (max-width: 576px) {
    &.edit-branch {
      padding-top: 250px;
    }
  }
}
