

.subsidies {
  .subsidy-section-header {
    border-bottom: 1px solid $gray-400;
  }
  .info_icon {
    color: $qurrah-light-blue;
    line-height: 15px;
    vertical-align: middle;
    font-weight: bolder;
    padding-inline-end: 5px;
  }
  .photo-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #f0f0f0;
    overflow: hidden;
    margin: 0 10px 0 0;

    body[dir='rtl'] & {
      margin: 0 0 0 10px;
    }

    img {
      width: 50px;
      height: 50px;
    }

    @media screen and (max-width: 575px) {
      margin: 0;
    }
  }

  .child-name {
    h6 {
      padding-top: 0;
      margin-bottom: 2px;
    }

    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }

  .child-buttons .qi {
    font-weight: 700;

    body[dir='ltr'] & {
      padding-right: 15px;
    }

    body[dir='rtl'] & {
      padding-left: 15px;
    }
  }

  .eligibility-status {
    border: 1px solid $primary;
    border-radius: 20px;
    color: $primary;
    text-transform: uppercase;
    padding: 2px 25px;
    margin: 10px 0;
    display: inline-block;

    &.eligibility-status--success {
      border: 1px solid $success;
      color: $success;
    } 
    &.eligibility-status--hrdf {
      border: 1px solid gold;
      color: gold;
    }
  }

  .divider {
    margin: 5px 0;
  }

  .subsidy-notification {
    position: absolute;
    right: 1.5em;
    left: auto;
    body[dir='rtl'] & {
      right: auto;
      left: 1.5rem;
    }
  }

  .close-icon {
    font-size: 1.5rem !important;
    background-color: transparent;
    color: rgb(255, 255, 255) !important;
    opacity: 1;
  }
  .status-NOT_ELIGIBLE {
    color: #f5365c;
    border: 1px solid #f5365c;
    border-radius: 20px;
    padding: 0 25px 3px 25px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
}

.uploaded-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0 10px;

  // body[dir='rtl'] & {
  //   justify-content: flex-end;
  // }

  i {
    color: $success;
    padding: 0 10px;
    font-size: 25px;
    font-weight: 700;
  }
}
