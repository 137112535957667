

.qurrah-modal {
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    padding: 3rem;

    @media screen and (max-width: 767px) {
      padding: 1rem;
    }
  }
  .modal-footer {
    border-top: none;
  }

  .close {
    position: absolute;
    right: 0;

    & > span:not(.sr-only) {
      color: $white;
    }

    body[dir='rtl'] & {
      left: 0;
      right: auto;
    }
  }
}
