.parent-dashboard__content {
  padding-bottom: 9rem;

  .table-responsive {
    padding: 10px 35px 0;

    @media screen and (max-width: 991px) {
      padding: 0;

      .table {
        margin-bottom: 0;
      }

      tr td:last-child {
        position: sticky;
        right: 0;
        background: #f16f70;
        padding: 0;
        bottom: 0px;

        body[dir='rtl'] & {
          right: auto;
          left: 0;
        }

        a {
          background: #f16f70;
          padding: 15px;
          display: flex;

          span {
            color: #fff !important;
          }
        }

        td:last-child:hover {
          background: transparent;
        }
      }
    }
  }

  .table .thead-light th {
    background: transparent;
    border-top: 0;
    text-transform: uppercase;
  }

  .table th,
  .table td {
    padding: 25px 16px;
  }
}

.profile,
.parent-edit {
  h2 {
    text-transform: uppercase;
  }

  .btn {
    i {
      font-weight: 700;
      padding: 0 5px 0 0;

      body[dir='rtl'] & {
        padding: 0 0 0 5px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .btn {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.parent-edit {
  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .cancel-btn {
      color: #56606b;
      background: transparent;
      border: none;
      box-shadow: none;

      &:hover,
      &:focus,
      &.btn-secondary:active {
        color: #333333;
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }
}

// .table .thead-light tr > th{
//   position: sticky;
//   z-index: 99;
//   top:0;
//   background: #ddd;
// }
