.accordian-title {
  body[dir='rtl'] & Button {
    //TODO: this is temp till fixing import issue
    text-align: right !important;
  }
}
.accordian-btn-plus::before {
  content: '+';
  position: absolute;
  bottom: 20px;
  line-height: 0;
  font-size: 25px;
  text-align: center;
  transition: all 0.3s;
  background-color: #fff;
  color: #62adc4;
  top: 1rem;

  body[dir='rtl'] & {
    left: 1rem;
  }
  body[dir='ltr'] & {
    right: 1rem;
  }
}
.accordian-btn-minus::before {
  content: '-';
  position: absolute;
  line-height: 0;
  bottom: 20px;
  font-size: 25px;
  text-align: center;
  transition: all 0.3s;
  background-color: #fff;
  color: #62adc4;
  top: 1rem;

  body[dir='rtl'] & {
    left: 1rem;
  }
  body[dir='ltr'] & {
    right: 1rem;
  }
}

.qurrah-accordian {
  & .collapse {
    border-top: 1px solid #ededed;
  }

  & button {
    padding-right: 50px;

    body[dir='rtl'] & {
      padding-left: 50px;
      padding-right: 1rem;
    }

    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }
}
