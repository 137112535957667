
.summary-card {
  padding: 30px;
  margin: 50px 0 30px;

  .col {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    line-height: initial;
  }

  h6 {
    padding-top: 3px;
  }

  .payment-btn {
    width: 100%;
  }
}

.divider {
  width: 100%;
  margin: 25px 0;
  border-top: 1px solid $gray-400;
}
