$stepper-desktop-padding: 20px;
$stepper-mobile-padding: 10px;
$stepper-font-size: 10px;
$stepper-color-done: #56606b;
$stepper-color-current: #172b4d;
$stepper-color-pending: #56606b;
$stepper-color-hover: #414952;
$stepper-divider-color: #ced4da;

$screen-xsm: 400px / 15px + 0em;
$screen-sm: 720px / 15px + 0em;
$screen-lg: 960px;

.stepper {
  padding: $stepper-mobile-padding;
  font-size: $stepper-font-size;

  @media (min-width: $screen-sm) {
    padding: $stepper-desktop-padding;
  }

  // List

  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: $screen-sm) {
      display: flex;
      justify-content: space-between;
    }

    // Items
    &__item {
      padding: 3px 5px;
      text-align: center;
      position: relative;
      text-transform: uppercase;

      display: flex;
      align-items: center;

      @media (min-width: $screen-sm) {
        padding: 10px;
        flex-direction: column;
        flex: 1;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;

        @media (min-width: $screen-sm) {
          width: calc(100% - 32px);
          top: 25px;
          left: calc(50% + 16px);
          border-top: 1px solid $stepper-divider-color;

          body[dir='rtl'] & {
            right: calc(50% + 16px);
            left: auto;
          }
        }
      }

      // Done
      &--done {
        color: $stepper-color-done;
        transition: all 0.1s;

        &:after {
          @media (min-width: $screen-sm) {
            border-top-style: solid;
            border-top-width: 1px;
          }
        }

        &:hover,
        &:focus {
          text-decoration: underline;
          cursor: pointer;
        }

        & .icon-done {
          background: #56606b;
        }
      }

      &--current {
        color: $stepper-color-current;

        &:last-of-type,
        &:only-of-type {
          &:after {
            height: 30%;
          }
        }

        & .icon-active {
          background: linear-gradient(45deg, #fabc68 0%, #f16f70 100%);
        }
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }

      &--pending {
        color: $stepper-color-pending;

        &:after {
          height: 30%;
        }

        & .icon-pending {
          background: #ced4da;
        }
      }
    }

    &__title {
      margin: 1px 0 0;

      @media (min-width: $screen-sm) {
        margin: 0;
      }
    }

    &__icon {
      margin: 0 10px 0 0;
      border-radius: 50%;
      font-size: 16px;
      font-weight: 700;
      padding: 0.5rem;
      color: #fff;

      body[dir='rtl'] & {
        margin: 0 0 0 10px;
      }

      @media (min-width: $screen-sm) {
        margin: 0 0 15px;

        body[dir='rtl'] & {
          margin: 0 0 15px;
        }
      }
    }
  }
}
