.branches-map {
  .map-container {
    width: 100%;
    height: 600px;
    position: relative;
  }
  .search_area {
    position: absolute;
    top: 20px;
    left: 40%;
    z-index: 10;
    width: 20%;
    @media screen and (max-width: 767px) {
      width: 80%;
      left: 10%;
    }
  }
  .map_loader {
    z-index: 20;
  }
}
