@mixin modal-variant($background) {
  .modal-title {
    color: color-yiq($background);
  }
  .modal-header,
  .modal-footer {
    border-color: rgba(color-yiq($background), 0.075);
  }
  .modal-content {
    background-color: $background;
    color: color-yiq($background);

    .heading {
      color: color-yiq($background);
    }
  }

  .close {
    position: absolute;

    & > span:not(.sr-only) {
      color: $white;
      position: absolute;
      right: 0;

      body[dir='rtl'] & {
        left: 0;
        right: auto;
      }
    }
  }
}
