
.image-slider {
  & .carousel-indicators {
    bottom: -50px;

    & .active {
      background-color: $primary;
    }

    & li {
      background-color: #ccc;
    }
  }

  .carousel-inner {
    height: 400px;
    background: rgba(0, 0, 0, 0.1);

    .carousel-item img {
      border-radius: 4px;
      min-height: 400px;

      @media screen and (max-width: 767px) {
        min-height: 250px;
      }
    }

    @media screen and (max-width: 767px) {
      height: 250px;
    }
  }

  .carousel-control-next {
    body[dir='rtl'] & {
      left: 0;
      right: auto;
    }
  }

  .carousel-control-prev {
    body[dir='rtl'] & {
      right: 0;
      left: auto;
    }
  }
}
