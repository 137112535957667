

.add-service__card {
  border-radius: 60px 10px;
  box-shadow: $box-shadow-lg;
  padding: 6%;
  margin-bottom: 32px;

  &:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
    background: $beige-bg;
  }
}

.add-service img {
  height: 64px;
}

.add-service__title {
  text-transform: capitalize;
  font-size: 32px;
  font-weight: bold;
  color: $qurrah-dark-blue;
}
.help-text {
  font-size: 11px;
}
