.sidebar-wrapper {
  background: #f6f1ea;
}

.dashboard-hero {
  background: linear-gradient(45deg, #fabc68 0%, #f16f70 100%);
  position: relative;
  max-height: 240px;

  & h1 {
    color: #fff;
  }

  & .dashboard-header {
    position: relative;
    top: -100px;

    & .card-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    & .card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & figure {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid #f16f70;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      & span {
        font-size: 32px;
        color: #f16f70;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard-hero {
    max-height: 100% !important;
    padding-bottom: 40px !important;

    & h1 {
      margin-bottom: 40px;
    }

    & .dashboard-header {
      position: static !important;
    }
  }
}

table {
  & thead th {
    border: none !important;
  }

  & tbody tr {
    &:hover {
      background: #f6f1ea;
    }
  }
}

.dashboard-hero--parent {
  & .dashboard-header {
    &__card {
      max-width: 100% !important;
      flex: 1;
    }
  }
}
