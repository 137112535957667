

.verification__alert {
  position: sticky !important;
  top: 0;
  z-index: calc(#{$zindex-dropdown} - 10);
}

.missing__info__alert {
  position: sticky !important;
  top: 0;
  z-index: calc(#{$zindex-dropdown} - 10);
  white-space: pre-line;
  span {
    line-height: 1.2rem;
  }
}
