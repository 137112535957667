@mixin coloredSquares($color1, $color2) {
    position: relative;
    text-align: center;
  
    &::before,
    &::after {
      content: '';
      position: absolute;
    }
  
    &::before {
      top: 0;
      left: -1rem;
      width: 20px;
      height: 20px;
      background-color: $color1;
      -webkit-clip-path: polygon(100% 0, 100% 50%, 50% 50%, 50% 100%, 0 100%, 0 0);
      clip-path: polygon(100% 0, 100% 50%, 50% 50%, 50% 100%, 0 100%, 0 0);
    }
  
    &::after {
      bottom: 0;
      right: -1rem;
      width: 20px;
      height: 20px;
      background-color: $color2;
      -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%, 0 50%, 50% 50%);
      clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%, 0 50%, 50% 50%);
    }
  }
  
  @mixin coloredBulletSquare($color) {
    li {
      list-style: none;
      position: relative;
  
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        width: 14px;
        height: 14px;
        background-color: $color;
        -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%, 0 50%, 50% 50%);
        clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%, 0 50%, 50% 50%);
  
        body[dir='rtl'] & {
          right: -22px;
        }
  
        body[dir='ltr'] & {
          left: -22px;
        }
      }
    }
  }
  
  @mixin responsive-font-size($minSize, $maxSize, $breakpoint) {
    font-size: min($minSize + ($maxSize - $minSize) * ($breakpoint / 100), $maxSize);
  }
  
  .unselectable {
    cursor: default;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  