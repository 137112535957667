.hero-container {
  background: url('/assets/images/hero-bg@2x.avif');
  border-radius: 0px 0px 180px 0px;
  background-size: cover;
  margin-top: -105px;
  min-height: 700px;
  background-repeat: no-repeat;
  height: 100vh;

  &:before {
    content: '';
    background: linear-gradient(
      45deg,
      rgba(250, 188, 104, 0) 0%,
      rgba(250, 188, 104, 1) 75%,
      rgba(241, 111, 112, 1) 100%
    );

    filter: hue-rotate(340deg);
    
    opacity: 0.5;
    border-radius: 0px 0px 180px 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  & .btn-outline-transparent,
  .btn-outline-transparent:hover {
    color: #62adc4;
  }
}

main.home {
  overflow: visible;
}

.hero-form {
  border-radius: 60px 4px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 15px 40px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 50px;
  max-width: 45vw;
  z-index: 1;
  right: 5%;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    font-size: 22px;
    margin-top: 30px;
  }

  & .btn-sm {
    border-radius: 15px;
  }

  & .btn span {
    body[dir='rtl'] & {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}


@media screen and (max-width: 991px) {
  .hero-form {
    max-width: 400px;
    right: 10%;
    border-radius: 30px 4px;
  }

  .hero-container {
    background-position: 45% 0;
  }

  .hero_search_header{
    display: block;
  }

  button.full-width {
    width: 100%;  
}
}

@media screen and (max-width: 767px) {
  .hero-form {
    position: relative;
    margin: 50px 20px;
    top: 120px;
    max-width: 100%;
    right: auto;
    background: rgba(255, 255, 255, 0.7);

    & h1 {
      font-size: 1.5rem;
    }

    & h2 {
      font-size: 1.2rem;
    }

    & .form-control {
      height: auto;
    }
  }

  .hero-container {
    min-height: auto;
    height: 100vh;
    background-position: 45% 0;
    border-radius: 0px;

    &:before {
      border-radius: 0px;
    }

    @media screen and (max-width: 767px) {
      height: 101vh;
    }
  }
}

@media screen and (max-width: 320px) {
  .hero-form {
    top: 120px;

    & h1 {
      font-size: 1rem;
    }

    & h2 {
      font-size: 0.9rem;
      margin-top: 20px;
    }

    & p {
      font-size: 0.8rem;
    }
  }

  .p-4 {
    padding: 1rem;
  }
}
