

.fieldset__legend {
  font-size: 0.65625rem;
  text-transform: uppercase;
  color: $gray-800;
  font-weight: $font-weight-bold;
}

.fieldset__icon {
  color: $qurrah-light-blue;
  font-size: 0.85rem;
  margin-top: -5px;
}

.fieldset__title {
  font-size: 0.75rem;
}

.fieldset__title--small {
  font-size: 0.65625rem;
}
