.user-menu {
  & .dropdown-menu {
    body[dir='ltr'] & {
      right: 5px;
      left: auto;

      &:before {
        right: 20px;
        left: auto;
      }
    }
  }
}
