

.tc-container {
    line-height: 1.8em;
    font-weight: 500;
    font-size: 1rem;
    ol, ul {
        li {
            padding-bottom: 0.5rem;
        }
    }

    ul{
        @include coloredBulletSquare($yellow);
        font-size: 0.95em;

        ul {
            @include coloredBulletSquare($primary);
            font-size: 0.9em;
        }
    }

    strong {
        color: $gray-dark;
        font-weight: bolder;
    }

    h2 {
        color: $blue;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5;
    }
}
