

// Variables
$payment-height: 50vh;
$card-height: 150px;
$card-height-mobile: 200px;
$card-margin-bottom: 32px;
$card-padding: 6%;
$card-border-radius: 60px 10px;
$card-img-height: 57px;
$hover-transition: all 0.3s ease-in-out;
$hover-transform: scale(1.05);
$error-font-size: 1rem;
$error-margin-bottom: 0.5rem;
$counter-font-size: 0.75rem;
$counter-margin-bottom: 0.5rem;
$apple-pay-font-size: 16px;

.payment-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $payment-height;
}

.payment-method__cards {
  margin-top: 32px;
}

.payment-method__card {
  border-radius: $card-border-radius;
  box-shadow: $box-shadow-lg;
  padding: $card-padding;
  margin-bottom: $card-margin-bottom;
  height: $card-height;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    transition: $hover-transition;
    transform: $hover-transform;
    background: $beige-bg;
  }

  img {
    height: $card-img-height;
  }

  @media screen and (max-width: 767px) {
    height: $card-height-mobile;
  }
}

.payment-initilize_error {
  font-size: $error-font-size;
  color: $red;
  font-weight: $font-weight-bold;
  margin-bottom: $error-margin-bottom;
  text-align: center;
}

.time-counter_reload {
  font-size: $counter-font-size;
  font-weight: $font-weight-bold;
  margin-bottom: $counter-margin-bottom;
  text-align: center;
}

.wpwl-form {
  max-width: 100% !important;
}

.wpwl-apple-pay-button {
  font-size: $apple-pay-font-size !important;
  display: block !important;
  width: 100% !important;
  -webkit-appearance: -apple-pay-button !important;
}