
.faq-container {
  line-height: 1.8em;
  font-size: 1rem;
  color: $gray-dark;

  p:has(~ ul) {
    font-size: 1rem;
    font-weight: 700;
  }

  ul {
    @include coloredBulletSquare($yellow);
    font-size: 0.95em;

    ul {
      @include coloredBulletSquare($primary);
      font-size: 0.9em;
    }
  }

  h2 {
    color: $blue;
  }
}

.faq-image {
  width: 600px;

  @media screen and (max-width: 768px) {
    width: 300px;
  }
  @media screen and (max-width: 365px) {
    width: 200px;
  }
}
