
$navbar-height: 105px;

$screen-md: 768px;
$screen-sm: 576px;
$screen-xs: 389px;

.about-subsidy {
  cursor: default;
  user-select: none;

  .top-banner {
    background-image: url('/assets/images/hero-bg.avif');
    background-position: 35% 20%;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;

    margin-top: -$navbar-height;

    @media (min-width: $screen-md) {
      height: 56vh;
    }

    @media (max-width: $screen-md) {
      max-height: 500px;
    }

    &:before {
      content: '';
      filter: brightness(0.7);
      background: linear-gradient(185deg, #d44f50d1 0%, #fabc688f 100%);
      opacity: 0.9;
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .title {
      @include coloredSquares($primary, $yellow);
      @include responsive-font-size(0.6rem, 4.5rem, 50);

      margin-top: 1rem;
      margin-bottom: 1rem;
      display: inline-block;
      color: $white;
      padding: 0 0.5rem 0.5rem 0;

      @media (min-width: $screen-xs) {
        margin-top: 4rem;
        margin-bottom: 2rem;
      }
    }

    p {
      line-height: 2rem;
      font-size: 1rem;

      @media (min-width: $screen-xs) {
        font-size: 1.25em;
      }

      @media (min-width: $screen-md) {
        font-size: 1.3rem;
      }
    }
  }

  .conditions-section,
  .mechanism-section {
    h2 {
      color: $gray-dark-2 !important;
    }

    p {
      font-size: 1rem;
    }

    &.mechanism-section {
      line-height: 1.8em;
      font-weight: 500;
      font-size: 1rem;

      ol,
      ul {
        li {
          padding-bottom: 0.5rem;
        }
      }

      ul {
        @include coloredBulletSquare($yellow);
        font-size: 0.95em;

        ul {
          @include coloredBulletSquare($primary);
          font-size: 0.9em;
          margin-top: 0.5rem;
        }
      }
    }

    .condition-icon {
      text-align: center;
      transition: transform 0.35s ease;
    }
    .condition-icon:hover {
      transform: scale(1.1);
    }
    .duration-icon {
      width: 45px;
      height: 45px;
    }
  }

  // .about-section {
  //   background: $beige-bg;
  //   body[dir='rtl'] & {
  //     border-bottom-right-radius: 200px;
  //   }
  //   body[dir='ltr'] & {
  //     border-bottom-left-radius: 200px;
  //   }
  // }

  // .funding-terms-box {
  //   background: $beige-bg;
  //   border-top-left-radius: 25px;
  //   border-bottom-right-radius: 25px;

  //   .col:last-child {
  //     text-align: end;
  //   }
  // }
  // .program-section {
  //   background: $beige-bg;
  //   border-top-right-radius: 200px;

  //   body[dir='rtl'] & {
  //     border-top-left-radius: 200px;
  //     border-top-right-radius: 0;
  //   }

  //   .program-card {
  //     border-top-left-radius: 25px;
  //     border-bottom-right-radius: 25px;
  //   }
  // }
  // .bottom-banner {
  //   background: url('/assets/images/investor-cta-bg.jpg');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   position: relative;

  //   &:before {
  //     content: '';
  //     background: linear-gradient(120deg, $qurrah-light-blue 0%, $qurrah-dark-blue 50%);
  //     opacity: 0.86;
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //   }
  // }

  // .funding-terms-list {
  //   ul li {
  //     padding: 20px;
  //   }
  //   ul {
  //     list-style: none;
  //   }
  //   ul li::before {
  //     content: '\2022';
  //     color: $primary;
  //     font-weight: bold;
  //     display: inline-block;
  //     width: 1em;
  //     font-size: 16px;
  //   }
  // }
}
