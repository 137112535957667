
.history {
  .history-item {
    position: relative;

    &:before {
      content: "";
      display: block;
      height: 90%;
      width: 0;
      border-left: 1px solid $gray-400;
      position: absolute;
      top: 40px;

      body[dir=ltr] & {
        left: 20px;
      }

      body[dir=rtl] & {
        right: 20px;
      }
    }

    @media screen and (max-width: 767px) {
      &:before {
        display: none;
      }
    }

    &:last-child:before {
      display: none;
    }

    .history-header {
      text-transform: none;
    }

    .history-dates {
      margin-bottom: 0;
    }
  }

  .history-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: $primary;
    background: linear-gradient($primary, $secondary);
    position: relative;
    margin-top: 15px;

    &.history-icon--completed {
      background: $gray-700;
    }

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-weight: 700;
      font-size: 20px;
    }
  }
}
