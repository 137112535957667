@include media-breakpoint-up(lg) {
  .container-lg {
    max-width: 1160px;
  }
}

// Offset classes for RTL - Added by Anas 21-07-2020

body[dir='rtl'] {
  .offset-1 {
    margin-right: 8.333333%;
    margin-left: 0;
  }

  .offset-2 {
    margin-right: 16.666667%;
    margin-left: 0;
  }

  .offset-3 {
    margin-right: 25%;
    margin-left: 0;
  }

  .offset-4 {
    margin-right: 33.333333%;
    margin-left: 0;
  }

  .offset-5 {
    margin-right: 41.666667%;
    margin-left: 0;
  }

  .offset-6 {
    margin-right: 50%;
    margin-left: 0;
  }
}

@media (min-width: 576px) {
  body[dir='rtl'] {
    .offset-sm-1 {
      margin-right: 8.333333%;
      margin-left: 0;
    }

    .offset-sm-2 {
      margin-right: 16.666667%;
      margin-left: 0;
    }

    .offset-sm-3 {
      margin-right: 25%;
      margin-left: 0;
    }

    .offset-sm-4 {
      margin-right: 33.333333%;
      margin-left: 0;
    }

    .offset-sm-5 {
      margin-right: 41.666667%;
      margin-left: 0;
    }

    .offset-sm-6 {
      margin-right: 50%;
      margin-left: 0;
    }
  }
}

@media (min-width: 768px) {
  body[dir='rtl'] {
    .offset-md-1 {
      margin-right: 8.333333%;
      margin-left: 0;
    }

    .offset-md-2 {
      margin-right: 16.666667%;
      margin-left: 0;
    }

    .offset-md-3 {
      margin-right: 25%;
      margin-left: 0;
    }

    .offset-md-4 {
      margin-right: 33.333333%;
      margin-left: 0;
    }

    .offset-md-5 {
      margin-right: 41.666667%;
      margin-left: 0;
    }

    .offset-md-6 {
      margin-right: 50%;
      margin-left: 0;
    }
  }
}

@media (min-width: 992px) {
  body[dir='rtl'] {
    .offset-lg-1 {
      margin-right: 8.333333%;
      margin-left: 0;
    }

    .offset-lg-2 {
      margin-right: 16.666667%;
      margin-left: 0;
    }

    .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0;
    }

    .offset-lg-4 {
      margin-right: 33.333333%;
      margin-left: 0;
    }

    .offset-lg-5 {
      margin-right: 41.666667%;
      margin-left: 0;
    }

    .offset-lg-6 {
      margin-right: 50%;
      margin-left: 0;
    }
  }
}

@media (min-width: 1200px) {
  body[dir='rtl'] {
    .offset-xl-1 {
      margin-right: 8.333333%;
      margin-left: 0;
    }

    .offset-xl-2 {
      margin-right: 16.666667%;
      margin-left: 0;
    }

    .offset-xl-3 {
      margin-right: 25%;
      margin-left: 0;
    }

    .offset-xl-4 {
      margin-right: 33.333333%;
      margin-left: 0;
    }

    .offset-xl-5 {
      margin-right: 41.666667%;
      margin-left: 0;
    }

    .offset-xl-6 {
      margin-right: 50%;
      margin-left: 0;
    }
  }
}
