
.edit-rating-stars-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: flex-end !important;
    gap:4px;

    .stars-wrapper{
        display: flex;
        gap:8px;
        justify-content: flex-end !important;
        .star-icon{
            font-size: 32px;
            cursor: pointer;
            &.qi-star-2 {
                color: $primary !important;
            }
        }
    }
    /* sub mission */
    .sub-message {
        font-size: x-small;
        width:180px;
        margin: 5px auto 0px !important;
        text-align: center;
    }
}