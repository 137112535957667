

$screen-sm: 720px / 15px;
.signup-toggle {
  margin: 20px 0;
}

.account-verification {
  .form-check-label {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
  }
}

.input--phone {
  & .input-group {
    body[dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }

  & input {
    direction: ltr;
    padding-left: 1rem !important;
  }

  & .input__addon {
    direction: ltr;
  }
}

.form-control {
  &.is-valid,
  &.is-invalid {
    background-image: none;

    body[dir='rtl'] & {
      padding-right: 0.75rem;
    }
  }
}

.register-on-hrdf-button button{
  width: 25%;
  @media (max-width: 767px) {
    width: 80%;
  }
}

.exclamation-icon{
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-45%, -50%);
  img{
    width: 12rem;
  }
}
