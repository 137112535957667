.main-nav {
  @media screen and (max-width: 991px) {
    padding: 1rem !important;

    .navbar-toggler {
      padding: 0.5rem !important;
    }
  }

  .navbar-logo{
    aspect-ratio: 2/2;
  }

  .navbar-logo-hrdf{
    aspect-ratio: 2/1 !important;
  }
  
  .mobile-user-menu {
    position: absolute;
    right: 60px;

    body[dir='rtl'] & {
      left: 60px;
      right: auto;
    }

    .nav-link {
      padding: 0.5rem !important;
    }

    .dropdown-menu.show {
      position: absolute !important;
      text-align: center;
      left: 0;

      body[dir='ltr'] & {
        right: -15px;
        left: inherit !important;
      }

      a {
        font-size: 0.8rem;
      }
    }
  }

  .mobile-notifications {


    .nav-link {
      padding: 0 !important;
    }

    .dropdown-menu.show {
      position: absolute !important;
      text-align: right;
      left: 0 !important;


      a {
        font-size: 0.8rem;
        border-bottom: 1px solid #ddd;

        p {
          white-space: pre-line;
          margin: 0 !important;
        }
      }
    }
  }
}

.maintainance_alert {
  // position: absolute !important;
  width: 100%;
  z-index: 99999;
  text-align: center;
  align-items: center;
  justify-content: center;

  .alert__message {
    line-height: 1.2;
    padding-right: 40px;

    body[dir='rtl'] & {
      padding-right: 0;
      padding-left: 40px;
    }
  }
}
