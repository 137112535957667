
$screen-sm: 720px / 15px + 0em;

.accreditation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  .accreditation-col {
    padding: 10px 20px;

    @media (max-width: $screen-sm) {
      padding: 10px;
      padding-left: 5px;
    }
  }

  .accreditation-logo {
    width: 125px;
    height: auto;
    margin: 0;

    @media (max-width: $screen-sm) {
      width: 100px;
    }

    img {
      width: 100%;
    }
  }

  .accreditation-col--logo {
    body[dir='ltr'] & {
      border-right: 5px solid $primary;
    }

    body[dir='rtl'] & {
      border-left: 5px solid $primary;
    }
  }

  .accreditation-col--text,
  & p {
    font-weight: 700;
    font-size: 0.95rem;
    margin: 0;
    line-height: 1.8;
    @media (max-width: $screen-sm) {
      font-size: 0.72rem;
    }
  }

  .accreditation-explanation:hover {
    box-shadow: none;
  }
}
