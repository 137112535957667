

.collapsible__header {
  transition: all 0.3s ease-in-out;
  background-color: $white !important;
  &:hover {
    cursor: pointer;
  }
}

.collapsible__title {
  color: $qurrah-dark-blue;
}

.collapsible__icon {
  color: $orange;
  padding-right: 8px;
  font-weight: $font-weight-extra-bold;

  body[dir='rtl'] & {
    padding-right: 0;
    padding-left: 8px;
  }
}
