

.add-center {
  margin: 32px auto;
}

.add-center__step {
  position: relative;
  width: 30%;
  border-radius: 60px 10px;
  box-shadow: $box-shadow-lg;
  padding: 6%;

  &:first-of-type::before {
    content: '1';
    position: absolute;
    display: block;
    top: 20px;
    left: 30px;
    color: #ced4da;
    font-size: 40px;
    z-index: 99999;

    body[dir='rtl'] & {
      left: auto;
      right: 30px;
    }
  }

  &:nth-of-type(2)::before {
    content: '2';
    position: absolute;
    display: block;
    top: 20px;
    left: 30px;
    color: #ced4da;
    font-size: 40px;
    z-index: 99999;

    body[dir='rtl'] & {
      left: auto;
      right: 30px;
    }
  }

  &:nth-of-type(3)::before {
    content: '3';
    position: absolute;
    display: block;
    top: 20px;
    left: 30px;
    color: #ced4da;
    font-size: 40px;
    z-index: 99999;

    body[dir='rtl'] & {
      left: auto;
      right: 30px;
    }
  }
}

.add-center__step--active {
  @extend .add-center__step;
  border: 2px solid $success;
}

.add-center__icon {
  font-size: 5rem;
  color: $primary;
}

.add-center__icon--active {
  @extend .add-center__icon;
  color: $success;
}

.add-center__status-icon {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 2rem;
}

.add-center__status-icon--active {
  @extend .add-center__status-icon;
  color: $success;
}

.add-center__status-icon--pending {
  @extend .add-center__status-icon;
  color: $secondary;
}

.add-center__status-icon--disabled {
  display: none;
}

.add-center__btn {
  width: 30%;
}

@media screen and (max-width: 767px) {
  .add-center__step {
    width: 100%;
    margin-bottom: 1rem;
    padding: 10%;

    p {
      margin-bottom: 0;
    }
  }

  .add-center__icon {
    font-size: 4rem;
  }
}
