
.datepicker-input{
  .input-group{
    .react-datepicker-wrapper{
      width:fit-content !important;
      flex:1;
      .customInput {
        border-right: none !important;
        border-left: 1px solid #ced4da !important;
        box-shadow: none !important;
        body[dir='rtl'] & {
          border-right: 1px solid #ced4da !important;
          border-left: none !important;
        }
      }
    }
    .input-group-text{
      border-left: none !important;
      box-shadow: none !important;
      body[dir='rtl'] & {
        border-left: 1px solid #ced4da !important;
        border-right: none !important;
      }
    }
  }
}

.disabled-date {
  pointer-events: none;
  color: $gray-400;
}

.react-datepicker-popper {
  body[dir='rtl'] & {
    right: 0px;
    left: auto;

    .react-datepicker__triangle {
      right: 30px;
    }
  }
}

.customInput {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 4px;
  height: 43px;
  min-height: 43px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
