

.photos__card-header {
  background-color: $white !important;
}

.photos__display-image {
  height: 150px;
  overflow: hidden;
  border: 1px dashed #ddd;

  & img {
    min-height: 150px;
  }
}

.photos__display-preview {
  height: fit-content !important;
  @include media-breakpoint-up(xl) {
    input {
      width: 60%;
      border: 1px solid $gray-300;
      background-color: $white;
      border-radius: 5px;
      padding: 0.5rem;
    }
  }
}

.photos__display-icon {
  margin-right: 8px;

  body[dir='rtl'] & {
    margin-left: 8px;
    margin-right: 0;
  }
}

.photos__card-title {
  color: $qurrah-dark-blue;
}

.help-text-icon {
  font-size: $icon-size-xs;
  font-weight: $font-weight-bold;
  color: $qurrah-light-blue;
}

.react-photo-gallery--gallery > div {
  justify-content: space-evenly;
}
