.tab-list {
  padding: 0;
  margin: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}


@media screen and (max-width: 767px) {
  .tab-list-item{
    padding: 0.5rem 0.24rem;
  }
}

.tab-list .btn-white:hover {
  transform: none;
  background-color: inherit;
  border-color: transparent;
}

.tab-list .btn.active {
  background-color: #f16f70 !important;
  border-color: #f16f70 !important;
  color: #fff !important;
}

.tab-list .btn-white {
  color: #f16f70 !important;
  min-width: 180px;
  min-height: 50px;
}
