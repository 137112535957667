
.add-child-card {
  h5 {
    margin: 10px 0;
  }

  h6 {
    margin: 20px 0;
  }
}

.copy-data {
  margin: 20px 0 10px;
}

.divider {
  width: 80%;
  height: 0;
  border-top: 1px solid $gray-light;
  margin: 10px auto;
}

.add-child-card {
  .emergency-contacts {
    .contact-btn {
      color: $gray-dark;
      background: transparent;
      border: none;
      box-shadow: none;

      &:hover,
      &:focus,
      &.btn-secondary:active {
        color: $yellow;
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.add-child-documents {
  .file-upload {
    max-width: 300px;

    &:not(.dz-started) {
      height: 80px;
    }

    .dz-message {
      margin: -10px 0 0;
    }

    .dz-button {
      border: none;
      background: transparent;
    }
  }
}
