
.branches-list {
  .accreditation-explanation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;

    .accreditation-col {
      padding: 30px 25px;
    }

    .accreditation-logo {
      width: 70px;
      margin: 0;

      img {
        width: 100%;
      }
    }

    .accreditation-col--logo {
      body[dir='ltr'] & {
        border-right: 2px solid #f9bc68;
      }

      body[dir='rtl'] & {
        border-left: 2px solid #f9bc68;
      }
    }

    .accreditation-col--text {
      p {
        margin: 0;
      }
    }
  }

  .accreditation-explanation:hover {
    box-shadow: none;
  }

  .yellow-border {
    box-shadow: none;
    border: 2px solid #f9bc68;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
  }
}

@media screen and (max-width: 767px) {
  .branches-list {
    .accreditation-explanation {
      flex-direction: column;
      padding: 20px;
      text-align: center;
      margin-bottom: 40px;
      margin-top: 10px;

      .accreditation-col--logo {
        border: none !important;
        margin-bottom: 10px;
      }

      .accreditation-col {
        padding: 0;
      }
    }
    .yellow-border {
      box-shadow: none;
      border: 2px solid #f9bc68;
      border-top-right-radius: 35px;
      border-bottom-left-radius: 35px;
    }
  }
}
