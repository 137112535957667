$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$navbar-height: 105px;

.content-component {
  .title-background {
    $padding-sm: 6vh;
    $padding-md: 9vh;
    $padding-lg: 12vh;

    margin-top: -$navbar-height;
    padding: 3vh;
    background: linear-gradient(0deg, #679db4 0%, #354d6f 100%);
    text-align: center;

    @media (min-width: $screen-sm) {
      padding: $padding-sm;
    }

    @media (min-width: $screen-md) {
      padding: $padding-md;
    }

    @media (min-width: $screen-lg) {
      padding: $padding-lg;
    }

    .title {
      @include coloredSquares($primary, $yellow);
      @include responsive-font-size(0.04rem, 5rem, 50);

      margin-top: 6rem;
      display: inline-block;
      color: $white;
      padding: 0.5rem;

      @media (min-width: $screen-sm) {
        margin-top: 2rem;
      }

      @media (min-width: $screen-md) {
        margin-top: 1rem;
        margin-bottom: 0;
      }

      @media (min-width: $screen-lg) {
        margin-top: 0;
      }
    }
  }

  .paper-container {
    background: $white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-top: 0;

    padding: 2rem 2rem;

    @media (min-width: $screen-sm) {
      margin-top: -2rem;
      margin-bottom: 2rem;
    }

    @media (min-width: $screen-md) {
      margin-top: -3rem;
    }

    @media (min-width: $screen-lg) {
      margin-top: -5rem;
    }
  }
}
