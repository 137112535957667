@import 'kit/bootstrap/functions';

@import 'kit/argon/argon-design-system/variables';

@import 'kit/bootstrap/bootstrap';

@import 'kit/argon/argon-design-system/theme';

@import 'kit/argon/react/react-differences';

@import 'mixin';

@import 'variables';

// import components styles
@import 'components/index';

// import screens styles
@import 'screens/index';

* {
  // font-family: 'SST Arabic';
}
.alert-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.App:has(.footer) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

div.pac-container {
  z-index: 1050 !important;
}

.spinner-border {
  margin: 20px auto;
}

.qurrah-loader {
  background: #fbfbfb;
}
small.error {
  color: red;
}

.services__badge--rejected {
  color: red;
}
.services__badge--waiting {
  color: rgb(67, 67, 201);
}
