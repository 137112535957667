
.pagination {
  .page-item {
    .page-link {
      color: white;
      background-color: $primary;
    }

    &.disabled .page-link {
      background-color: $gray-light;
    }
  }
  .page-item span {
    font-size: 1.2rem;
  }
}
