.sidebar {
  transition: all 0.5s;
  position: sticky;

  &.is-open {
    margin-left: 0;
    transition: 0.5s;
  }

  // &-header h3 {
  //     color: #fff;
  //     padding: 1em;
  // }

  .menu-open {
    background: #6d7fcc;
  }

  .nav-item:hover {
    color: #f16f70;
  }

  li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
  }

  &-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
}

.side-menu {
  overflow-y: scroll;

  & ul {
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #f16f70;
  }

  &__icon {
    font-size: 16px;
    margin: 0 5px;
    color: #f16f70;
  }
  &_indicator_icon {
    font-size: 16px;
    margin: 0 10px;
    color: #f16f70;
  }
}
