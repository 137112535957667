

.result__icon {
  font-size: $icon-size-xl;
  color: $green;
}

.result__icon--failure {
  @extend .result__icon;
  color: $red;
}
