.filter-badges {
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #f16f70;
    margin-bottom: 10px;

    & i {
      font-size: 1rem;
      color: #f16f70;
      font-weight: 700;
      padding-left: 5px;

      body[dir='rtl'] & {
        padding-left: 0;
        padding-right: 5px;
      }
    }

    &:hover {
      i {
        color: #fff;
      }
    }
  }
}
