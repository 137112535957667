.alert {
    border-radius: 15px;
    padding: 20px;
    background-color: red;
    color: white;
    border: 1PX solid pink;
    max-width: 450px;
    width: 100%;
}
.alert a{
    color: white;
}