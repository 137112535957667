
$screen-xsm: 400px / 15px + 0em;
$screen-sm: 720px / 15px + 0em;
$screen-lg: 960px;

.how-it-works {
  .title {
    @include coloredSquares($primary, $yellow);

    display: inline-block;
    padding: 1rem;
  }

  .header {
    h2 {
      color: $gray-dark-2;
    }

    p {
      line-height: 2rem;
      font-size: 1rem;

      @media (min-width: $screen-xsm) {
        font-size: 1.25em;
      }

      @media (min-width: $screen-sm) {
        font-size: 1.3rem;
      }
    }
  }

  & .tabs .tab-list {
    @media (max-width: $screen-sm) {
      flex-direction: column;

      & .tab-list-item button {
        width: 100%;
      }
    }
  }

  .steps {
    padding: 10px;
    font-size: 10px;
    font-weight: 700;

    @media (min-width: $screen-sm) {
      padding: 20px;
    }

    // List

    &__list {
      width: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;

      @media (min-width: $screen-sm) {
        display: flex;
        justify-content: space-between;
      }

      // Items
      &__item {
        padding: 3px 5px;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;

        @media (min-width: $screen-sm) {
          padding: 10px;
          flex-direction: column;
          flex: 1;
        }

        @media (max-width: $screen-sm) {
          padding: 10px 10px 10px 30px;
          flex-direction: column;
          align-items: flex-start;

          body[dir='rtl'] & {
            padding: 10px 30px 10px 10px;
          }
        }

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          position: absolute;
          background: #f16f70;
          border-radius: 50%;
          margin-top: -11px;

          @media (max-width: $screen-sm) {
            width: 10px;
            height: 10px;
            margin-top: 10px;
            left: 10px;

            body[dir='rtl'] & {
              left: auto;
              right: 10px;
            }
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 2;

          @media (min-width: $screen-sm) {
            width: calc(100% - 32px);
            top: 6%;
            left: calc(50% + 16px);
            border-top: 1px solid #ced4da;

            body[dir='rtl'] & {
              right: calc(50% + 16px);
              left: auto;
            }
          }

          @media (max-width: $screen-sm) {
            height: calc(100%);
            top: 20px;
            left: 15px;
            border-left: 1px solid #ced4da;
            z-index: -1;

            body[dir='rtl'] & {
              right: 15px;
              left: auto;
            }
          }
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }

        & .circle {
          background: linear-gradient(45deg, #fabc68 0%, #f16f70 100%);
          width: 20px;
          height: 20px;

          @media (max-width: $screen-sm) {
            width: 10px;
            height: 10px;
          }
        }

        & .step-title {
          padding-top: 20px;
          font-size: 24px;
          color: #172b4d;

          @media (max-width: $screen-sm) {
            font-size: 18px;
            padding-top: 0;
            text-align: left;

            body[dir='rtl'] & {
              text-align: right;
            }
          }
        }

        & .step-text {
          text-transform: none;
          font-size: 1rem;
          font-weight: 700;

          @media (max-width: $screen-sm) {
            text-align: left;

            body[dir='rtl'] & {
              text-align: right;
            }
          }
        }
      }

      &__title {
        margin: 1px 0 0;

        @media (min-width: $screen-sm) {
          margin: 0;
        }
      }
    }
  }

  &__icon {
    font-size: 72px;
    color: #f16f70;

    @media (max-width: $screen-sm) {
      font-size: 60px;
    }
  }

  & .card {
    cursor: pointer;
    min-height: 255px;
    border: 1px solid transparent;
    @media (max-width: $screen-sm) {
      width: 160px;
      height: 160px;
      min-height: 160px;
    }

    & .card-body {
      padding: 3rem;
      @media (max-width: $screen-sm) {
        padding: 5px;
        h3 {
          font-size: 0.9rem;
        }
      }
    }
  }

  & .card:hover {
    border: 1px solid $primary;
  }
}

@media screen and (max-width: 767px) {
  .how-it-works {
    .accreditation-explanation {
      flex-direction: column;
      padding: 20px;
      text-align: center;
      margin-bottom: 40px;
      margin-top: 10px;

      .accreditation-col--logo {
        border: none !important;
        margin-bottom: 10px;
      }

      .accreditation-col {
        padding: 0;
      }
    }
    .yellow-border {
      box-shadow: none;
      border: 2px solid #f9bc68;
      border-top-right-radius: 35px;
      border-bottom-left-radius: 35px;
    }
  }
}

.how-it-works__modal {
  .steps {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background-color: #ced4da;
    }

    &__list {
      list-style-type: none;
      padding: 0;

      &__item {
        width: 50%;
        padding: 5px 20px;
        position: relative;

        &:nth-child(even):before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: #f16f70;
          border-radius: 50%;
          left: -6px;
          top: 18px;
          transition: 0.5s;

          body[dir='rtl'] & {
            right: -6px;
            left: auto;
          }
        }

        &:nth-child(odd):before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: #f16f70;
          border-radius: 50%;
          right: -6px;
          top: 18px;
          transition: 0.5s;

          body[dir='rtl'] & {
            left: -6px;
            right: auto;
          }
        }

        &:nth-child(even) {
          float: right;
          text-align: left;
          clear: both;

          body[dir='rtl'] & {
            float: left;
            text-align: right;
            clear: both;
          }
        }

        &:nth-child(odd) {
          float: left;
          text-align: right;
          clear: both;

          body[dir='rtl'] & {
            float: right;
            text-align: right;
            clear: both;
          }
        }
      }
    }

    @media (max-width: $screen-sm) {
      &:before {
        left: 0;

        body[dir='rtl'] & {
          right: 0;
          left: auto;
        }
      }
      & ul li {
        width: 100%;
        padding: 3px 0px 40px 20px;

        body[dir='rtl'] & {
          padding: 3px 20px 40px 0;
        }
      }
      & ul li:nth-child(even):before {
        left: -6px;

        body[dir='rtl'] & {
          right: -12px;
          left: auto;
        }
      }
      & ul li:nth-child(odd):before {
        left: -6px;
        right: auto;

        body[dir='rtl'] & {
          right: -12px;
          left: auto;
        }
      }
      & ul li:nth-child(even) {
        float: left;
        text-align: left;

        body[dir='rtl'] & {
          float: right;
          text-align: right;
        }
      }
      & ul li:nth-child(odd) {
        float: left;
        text-align: left;

        body[dir='rtl'] & {
          float: right;
          text-align: right;
        }
      }
    }
  }
}
// .center_register_help-msg {
//   .accreditation-explanation {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 30px;

//     .accreditation-col {
//       padding: 30px 25px;
//     }

//     .accreditation-logo {
//       width: 70px;
//       margin: 0;

//       img {
//         width: 100%;
//       }
//     }

//     .accreditation-col--logo {
//       body[dir='ltr'] & {
//         border-right: 1px solid #56606b;
//       }

//       body[dir='rtl'] & {
//         border-left: 1px solid #56606b;
//       }
//     }

//     .accreditation-col--text {
//       p {
//         margin: 0;
//       }
//     }
//   }
// }
