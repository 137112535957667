

.gallery-item__wrapper {
  max-width: 20vw;
  overflow: hidden;
  height: 250px;
}

.gallery__image {
  min-height: 250px;
  height: auto;
  width: 100%;
}

.gallery-item__field {
  background-color: $gray-200;
  padding: 0.5rem 0.75rem;
  position: relative;

  .form-group {
    margin-bottom: 0;
  }
  .form-check-input{
    top: -3px;  
  }
  label {
    width: 100%;
  }
}
