
.services__previous {
  background-color: $gray-200;
  border-radius: 5px;
  padding: 12px;
  border: 1px solid $gray-300;
  margin: auto 15px;
  .form-group {
    margin: 0;
  }
}
