
.action-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $gray-700;
  padding: 20px 40px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  .btn-danger.disabled-btn {
    background: $gray-400;
    border: $gray-400;
    opacity: 1;
  }

  a {
    padding: 0 40px;
    color: $white;

    &:hover {
      color: $secondary;
    }
  }
}

@media screen and (max-width: 576px) {
  .action-bar {
    flex-direction: column;
    justify-content: center;

    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      a {
        margin: 20px 0;
      }
    }
  }
}



.action-bar__wrapper {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $gray-700;
  padding: 1.5rem 3rem;
  z-index: $zindex-sticky;
  .btn-danger.disabled-btn {
    background: $gray-400;
    border: $gray-400;
    opacity: 1;
  }
  .action-bar__cancel {
    color: $white;
    &:hover {
      color: $secondary;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 576px) {
  .action-bar__wrapper {
    flex-direction: column;
    justify-content: center;
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      .action-bar__cancel {
        margin: 20px 0;
      }
    }
  }
}
