.booking-status-popover {
  vertical-align: text-bottom;
  margin:5px 10px;

  .info-btn {
    background-color: transparent;
    border: none;
    color: #11cdef;
    font-size: 12px;
    line-height: 18px;
  }
  .info-btn:focus {
    outline: 0;
  }
}
.status-popover-auto {
  box-shadow: none !important;
  border: 1px solid #fabc68 !important;
}
.status-info-body {
  left: 50px;
  padding: 5px !important;
  h6 {
    padding-top: 2px;
  }
}
