

.search-box {
  .input-group-append > .btn {
    border-radius: 0 4px 4px 0;

    span {
      font-size: 15px;
      font-weight: bold;
    }

    body[dir='rtl'] & {
      border-radius: 4px 0 0 4px;
    }
  }

  .input-group .search-field.form-control {
    padding-left: 15px;

    body[dir='rtl'] & {
      border-right: 1px solid $gray-400;
      border-left: 0;
      border-radius: 4px 4px 0 0;
      padding-right: 15px;
    }
  }
}
