

.license-status {
  border: 1px solid $primary;
  border-radius: 20px;
  color: $primary;
  text-transform: uppercase;
  padding: 2px 25px;
  margin: 10px 0;
  display: inline-block;
}
.license-status--ACTIVE,
.license-status--ACCREDITED {
  border: 1px solid $green;
  color: $green;
}

.license-status--REJECTED {
  border: 1px solid $red;
  color: $red;
}

.licenses {
  .card-title {
    border-bottom: 1px solid $gray-400;

    h5 {
      text-transform: uppercase;
    }
  }

  .license-file-btn {
    margin: 10px 0;
  }

  .qurrah-alert {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;

    &.qurrah-alert--info {
      background-color: $qurrah-light-blue;
    }

    i {
      font-weight: 700;
      font-size: 20px;
    }

    span {
      font-size: 15px;
    }
  }

  .license-info {
    font-size: 18px;

    i {
      color: $qurrah-light-blue;
      font-weight: 700;
    }
  }

  .iban-status {
    border: 1px solid $primary;
    border-radius: 20px;
    color: $primary;
    text-transform: uppercase;
    padding: 2px 25px;
    margin: 10px 0;
    display: inline-block;
  }

  .iban-status--ACCEPTED {
    border: 1px solid $green;
    color: $green;
  }

  .iban-status--PENDING {
    border: 1px solid $orange;
    color: $orange;
  }

  .iban-status--REJECTED {
    border: 1px solid $red;
    color: $red;
  }

  .btn-secondary.btn-cancel,
  .btn-secondary.btn-cancel:hover,
  .btn-secondary.btn-cancel:active,
  .btn-secondary.btn-cancel:focus,
  .btn-secondary.btn-cancel:not(:disabled):not(.disabled):active {
    background: transparent;
    color: $qurrah-light-blue;
    text-transform: none;
    border: none;
    box-shadow: none;
  }

  .spinner-border {
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }
}
