.filters-wrapper {
  background: #f6f1ea;
}

.search-box {
  & .filter-heading {
    font-weight: bold;
    text-transform: uppercase;
    color: #f16f70;
    margin-bottom: 20px;
  }
}

.filter-btn {
  text-align: left !important;

  body[dir='rtl'] & {
    text-align: right !important;
  }
}

.filter-btn:hover,
.filter-btn:focus {
  box-shadow: none !important;
}

.filter-btn__container {
  bottom: 0;
  left: 0;
  position: fixed !important;
  z-index: 999999;
  background: #fff;
  width: 100vw;
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.filter_modal {
  background-color: white;

  .modal-dialog {
    margin: 0;
    height: 100vh;
  }

  .fade {
    bottom: 0;
  }

  .filter_modal_content {
    box-shadow: none !important;
    height: 100%;

    .modal-header {
      align-items: center;

      & button.close {
        display: inline-flex;

        & span {
          font-size: 32px;
          margin-top: -3px;
        }
      }
    }
  }
}

.filter-search-field__btn {
  background: #fff !important;
  background-color: #fff !important;
  color: initial !important;
  border-color: #ced4da !important;
  border-left: none !important;
  box-shadow: none !important;
  padding: 0 0.6rem !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  cursor: default !important;

  body[dir='rtl'] & {
    border-left: 1px solid #ced4da !important;
    border-right: none !important;
  }

  & .qi-i-remove {
    font-size: 1.5em;
    cursor: pointer !important;
  }

  & .qi-zoom {
    body[dir='rtl'] & {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}
