
.center-booking {
  h5 {
    padding: 10px 0;
    margin: 20px 0;
    border-bottom: 1px solid $gray-400;
    font-size: 25px;
  }

  .booking-status {
    border: 1px solid $primary;
    border-radius: 20px;
    color: $primary;
    text-transform: uppercase;
    padding: 2px 25px;
    margin: 10px 0;
    display: inline-block;
  }

  .booking-status--COMPLETED,
  .booking-status--CONFIRMED,
  .booking-status--ACCEPTED {
    border: 1px solid $green;
    color: $green;
  }

  .booking-status--REJECTED,
  .booking-status--CANCELLED {
    border: 1px solid $red;
    color: $red;
  }

  .booking-status--PENDING {
    border: 1px solid $orange;
    color: $orange;
  }

  .required-documents {
    &__item {
      padding: 10px 0;
    }

    h6 {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        margin-bottom: 8px;
      }
    }
  }

  .qi-data-download {
    font-weight: 700;
    padding: 0 5px 0 0;

    body[dir='rtl'] & {
      padding: 0 0 0 5px;
    }
  }

  .summary__price {
    .divider {
      margin: 0;
      border-color: $gray-400;
    }

    h6 {
      padding-top: 0;
    }

    h5 {
      margin-top: 0;
    }
  }

  .summary__status {
    border-top: 1px solid $gray-800;
    margin-top: 30px;
    padding-top: 20px;

    h5 {
      border: none;
      margin: 0;
    }

    .accept-btn {
      margin: 20px 0;
      width: 100%;

      span {
        font-weight: 700;
        padding: 0 15px 0 0;

        body[dir='rtl'] & {
          padding: 0 0 0 15px;
        }
      }
    }

    .reject-btn {
      width: 100%;

      span {
        font-weight: 700;
        padding: 0 15px 0 0;

        body[dir='rtl'] & {
          padding: 0 0 0 15px;
        }
      }
    }
  }

  .summary-checkbox {
    &:before {
      content: '';
      cursor: pointer;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid $gray-400;
      border-radius: 4px;
      background: $white;
      position: absolute;
      top: -3px;
      left: -4px;
    }

    &:checked:before {
      background: $primary;
    }

    &:checked:after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 6px;
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-50deg);
      top: 2px;
      left: 1px;
    }
  }

  .summary-checkbox--label {
    font-size: 14px;
    text-transform: none;

    body[dir='rtl'] & {
      padding-right: 20px;
    }
  }

  .center-booking__terms {
    &:hover {
      cursor: pointer;
    }
  }

  & ul {
    list-style: none;
    padding: 0;

    & li {
      display: flex;
      align-items: center;

      &:before {
        content: '\eb7e';
        color: #f16f70;
        font-size: 1.25rem;
        font-weight: 800;
        padding: 0 8px 0 2px;
        font-family: 'Qurrah', sans-serif;
      }
    }
  }
}
.modal-footer {
  display: flex;
  flex-direction: column;
  p {
    align-self: baseline;
    margin-right: 0;
    ol {
      padding-right: 13px;
    }
    ul {
      padding-right: 20px;
    }
  }
  .row {
    display: flex;
  }
}
