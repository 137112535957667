$screen-sm: 720px / 15px + 0em;

.footer {
  background: $qurrah-dark-blue;
  padding: 1.5rem 0;
  color: $footer-color;

  &:not(.is-hrdf){
    .footer-hrdf-logo {
      width: 18rem;
      height: auto;
    }
    
  }

  background-position: 65% 105%;
  background-repeat: no-repeat;

  &.is-hrdf{
    background-image: none !important;
  }
  
  @media (max-width: $screen-sm) {
    text-align: center;
  }

  body[dir='rtl'] & {
    background-position: 35% 105%;
  }

  .col-footer {
    .heading {
      color: $footer-heading-color;
      letter-spacing: 0;
      font-size: $footer-heading-font-size;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 2.2rem;
        &:not(:first-child):not(:last-child) {
          border-bottom: 1px solid #ced4da33;
          padding-bottom: 15px;
        }
    }
  }

  .nav .nav-item .nav-link,
  .footer-link {
    color: $footer-link-color !important;

    &:hover {
      color: $footer-link-hover-color !important;
    }
  }

  .list-unstyled-ul li a {
    display: inline-block;
    padding: 0.125rem 0;
    color: $footer-link-color;
    font-size: $footer-link-font-size;
    line-height: 2.1rem;
    &:hover {
      color: $footer-link-hover-color;
    }
  }

  .social-container{
    display: flex;
    justify-content: flex-start;

    @media (max-width: $screen-sm) {
      justify-content: center;
    }
  }

  .copyright {
    font-size: $font-size-sm;
  }

  .dropdown {
    .btn:not(:disabled):not(.disabled):active:focus,
    .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
    }
  }

  .footer-logo {
    height: 50px;
  }

  .sub-header {
    color: $footer-heading-color;
  }
  .working-hours-details {
    line-height: 2rem;
  }

  .social-icon {
    width: 45px;
    height: 45px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 14px 3px;
    background: $qurrah-light-blue;
    transition: border-radius 0.3s, background-color 0.3s;
    fill: white;

    margin: 0 0.25rem;

    a {
      fill: inherit;
    }

    svg {
      width: 100%;
      height: auto;
      transition: transform 0.3s ease-out;
    }

    &:hover {
      border-radius: 3px 14px;
      background: white;
      fill: $qurrah-light-blue;

      svg {
        transform: scale(1.15);
      }
    }
  }
}

.footer-dark {
  .col-footer .heading {
    color: $white;
  }
}

.footer.has-cards {
  overflow: hidden;
  padding-top: 500px;
  margin-top: -420px;
  position: relative;
  background: transparent;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 600px;
    height: 2000px;
    background: theme-color('secondary');
    transform: skew(0, -8deg);
  }

  .container {
    pointer-events: auto;
    position: relative;
  }
}

.nav-footer {
  .nav-link {
    font-size: $font-size-base;
  }

  .nav-item:last-child {
    .nav-link {
      padding-right: 0;
    }
  }
}
