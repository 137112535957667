
.view-rating-stars-component{
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    gap:10px;
  .view-rating-container {
      display: flex;
      justify-content: space-between;
      gap:2px;

      .view-rating-stars-wrapper {
          display: flex;
          gap:4px;
          i {
              color: $secondary;
              font-size: 20px;
        
              & + span {
                margin: 0 10px;
              }
            }
      }
      .view-rating-stars-label{
          display: flex;
          gap:3px;
          color: $dark;
      }
  }
  .important-note{
    font-size: x-small;
    color: $default !important;
  }
}