
$screen-xs: 720px;

.center-account {
  .edit-account-btn {
    float: right;
    margin-top: -60px;

    i {
      font-weight: 700;
      padding: 0 5px 0 0;

      body[dir='rtl'] & {
        padding: 0 0 0 5px;
      }
    }

    body[dir='rtl'] & {
      float: left;
    }

    @media (max-width: $screen-xs) {
      margin-top: 10px;
      width: 100%;
    }
  }
}
