.branch-card {
  & .card {
    transition: all 0.3s ease-in-out;

    & .card-body {
      padding: 1.2rem;
      position: relative;

      & .card-title {
        font-size: 17px;
        font-weight: bold;
        color: #172b4d;
        height: 48px;
        margin-bottom: 1rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      & .card-text {
        color: #56606b;
        margin-bottom: 0;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    & .card-img-container {
      position: relative;
      margin: 0;
      height: 150px;
      overflow: hidden;

      img {
        min-height: 150px;
      }

      .center-type-tag {
        background: #f16f70;
        border-radius: 30px;
        width: auto;
        color: #fff;
        font-size: 10px;
        text-transform: uppercase;
        padding: 5px 8px;
        position: absolute;
        bottom: 0;
        left: 10px;
        z-index: 99;

        body[dir='rtl'] & {
          right: 10px;
          left: auto;
        }
      }
      &:after {
        content: '';
        background: linear-gradient(45deg, #fabc68 0%, #f16f70 100%) !important;
        position: absolute;
        width: 100%;
        height: 0;
        z-index: 9;
        display: block;
        top: 0;
        left: 0;
        opacity: 0;
        border-radius: 0.25rem 0.25rem 0 0;
        transition: all 0.3s ease-in-out;
      }
      &:before {
        content: '';
        background: #172b4d;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: block;
        top: 0;
        left: 0;
        opacity: 0.1;
        border-radius: 0.25rem 0.25rem 0 0;
        transition: all 0.3s ease-in-out;
      }
    }

    & .accreditation-logo {
      position: absolute;
      width: 50px;
      height: 35px;
      top: 0;
      right: 10px;
      background: #fff;
      padding: 5px 8px;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
      z-index: 99;

      & img {
        width: 100%;
      }

      body[dir='rtl'] & {
        right: auto;
        left: 10px;
      }
    }

    &:hover {
      background: #f6f1ea;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease-in-out;

      .card-img-container:after {
        opacity: 0.8;
        transition: 0.3s ease-in-out;
        height: 100%;
      }
    }
  }
}
