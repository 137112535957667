
$screen-sm: 720px / 15px + 0em;

.booking-service {
  label {
    font-size: 13px;
  }

  .status-col {
    body[dir='ltr'] & {
      text-align: right;
    }

    body[dir='rtl'] & {
      text-align: left;
    }
  }

  .service-facilities,
  .booking-pricing {
    label {
      font-weight: 400;
      font-size: 14px;
    }

    .price-col {
      body[dir='ltr'] & {
        text-align: right;
      }

      body[dir='rtl'] & {
        text-align: left;
      }
    }
  }

  .service-documents {
    h6 {
      text-transform: none;
      font-weight: 400;
      padding-top: 0;

      @media (min-width: $screen-sm) {
        margin-bottom: 0;
      }
    }

    .form-group span {
      display: none;
    }

    .document-name-col {
      display: flex;
      align-items: center;
    }

    &__item {
      padding: 10px;
      margin-bottom: 10px;
    }
  }

  .selected-facility {
    i {
      color: $primary;
      font-weight: 700;
      padding: 0 5px;
    }
  }
}

.booking-success {
  i {
    &.qi-n-check {
      color: $success;
      font-size: 100px;
    }

    &.qi-c-info-1 {
      color: $qurrah-light-blue;
      font-weight: 700;
      padding: 0 5px;
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    div {
      padding: 20px;

      &:first-child {
        body[dir='ltr'] & {
          border-right: 1px solid $gray-600;
        }

        body[dir='rtl'] & {
          border-left: 1px solid $gray-600;
        }

        @media (max-width: $screen-sm) {
          border-right: 0;

          body[dir='rtl'] & {
            border-left: 0;
          }
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }
  }
}

.booking-modal {
  .modal-content {
    background: $danger;
    color: $white;

    .modal-body {
      padding: 35px;
    }

    .booking-modal__description {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      a {
        color: $secondary;
      }
    }

    i {
      font-size: 25px;
      font-weight: 700;
      padding: 0 5px;
    }

    .btn-make-booking {
      color: $danger;
    }

    .btn-cancel {
      background: transparent;
      color: $white;
      border: none;
      box-shadow: none;
      text-transform: none;

      &:hover,
      &:focus,
      &.btn-secondary:active {
        color: $white;
        background: transparent;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.choose-child {
  & .form-group {
    margin-bottom: 0;
  }

  & .info__wrapper {
    margin-bottom: 0;
    margin-top: 1rem;
    padding: 0 5px;
  }
}

/* begin:: add rating for cetner step */
.add-rating-section {
  .row{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px !important;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    div{
      display: flex;
      align-items: center;
      @include media-breakpoint-down(lg) {
        justify-content: center;
        text-align: center !important;
      }
      &.stars-icons-col{
        @include media-breakpoint-up(lg) {
          justify-content: flex-end;
        }
      }
    }
  }
}
/* end:: add rating for cetner step */