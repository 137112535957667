// Colors

$white: #ffffff;
$gray-light: #c0c6ca;
$gray-dark-2: #56606b;
$gray-dark: #333333;
$yellow: #fabc68;
$orange: #fb6340;
$red: #f5365c;
$navy: #183057;
$green: #2dce89;
$primary: #f16e70;
$blue: #3B879E;
