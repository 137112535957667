.notifications {
  & .dropdown-menu {
    max-height: 330px;
    width: 300px;
    padding: 0;
    overflow: auto;
    text-align: right;
    position: relative;
    border-top-right-radius: 8px;

    body[dir='ltr'] & {
      right: 5px;
      left: auto;
      text-align: left;

      &:before {
        right: 20px;
        left: auto;
      }
    }

    a {
      font-size: 0.8rem;
      border-bottom: 1px solid #f6f6f6;
      p {
        white-space: pre-line;
        margin: 0;
      }
    }

    & .menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0; 
      background-color: #ffffff;
      border-bottom: 1px solid #e0dfdf;
      padding: 10px 15px;
      z-index: 1;

      & span {
        font-size: 18px;
        margin-top: -5px;
        cursor: pointer;
      }
      p {
        margin: 0;
        margin-top: 5px;
        cursor: default;
      }
    }
  }
}

.notifications__badge {
  transform: scale(0.8);
  position: absolute;
  top: -5px;
  right: -5px;
}

.notifications__icon {
  width: 20.5px;
}