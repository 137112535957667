.children-table {
  tr:first-child {
    td {
      border-top: 0;
    }
  }
}

.child-photo--wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.add-btn--wrapper {
  @media screen and (max-width: 575px) {
    margin-top: 20px;

    button,
    a {
      display: flex;
      margin-bottom: 10px;
      width: 100%;
      margin-right: 0 !important;
      margin-left: 0 !important;
      justify-content: center;
    }
  }
}

.card--child {
  position: relative;
  height: 100%;

  & .card-body {
    text-align: center;
    padding: 2rem;
  }

  & .child-photo--wrapper {
    width: 80px;
    height: 80px;
    margin: 0 auto;

    img {
      min-height: 80px;
    }
  }

  & .card-title {
    font-weight: 700;
    font-size: 25px;
    margin: 10px 0 20px 0;
    color: $navy;
  }

  & .card-subtitle {
    color: $gray-dark-2;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  & .card-text {
    color: $gray-light;
  }

  & .HRDF-logo {
    position: absolute;
    width: 50px;
    height: 35px;
    top: 10px;
    left: 10px;
    background: #fff;
    padding: 5px 8px;
    border-radius: 0 0 4px 4px;
    z-index: 99;

    & img {
      width: 130%;
    }

    body[dir='rtl'] & {
      right: 10px;
      left: auto;
    }
  }
}

.ELIGIBLE-border {
  border: solid 3px #3dce89 !important;
}
.status-ELIGIBLE {
  background-color: $green;
  color: white;
  border: 1px solid $green;
  border-radius: 20px;
  padding: 0 25px 3px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.status-CHILD_ADDED {
}
.status-DRAFT {
}
.status-PENDING {
  color: #f66340;
  border: 1px solid #f66340;
  border-radius: 20px;
  color: white;
  padding: 0 25px 3px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.status-UNDER_REVIEW {
  color: white !important;
}
.status-NOT_ELIGIBLE {
  color: #f5365c;
  border: 1px solid #f5365c;
  border-radius: 20px;
  padding: 0 25px 3px 25px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.alert__custom__subsidy {
  width: 100%;
  z-index: 99;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: None;
  border-radius: 4px !important;
  background-color: $qurrah-light-blue !important;

  .alert__message {
    line-height: 1.2;
    padding-right: 40px;
    text-align: left;

    body[dir='rtl'] & {
      padding-right: 0;
      padding-left: 40px;
      text-align: right;
    }
  }

  .alert__button {
    color: $qurrah-light-blue !important;
  }
}
