
$screen-sm: 720px / 15px + 0em;

.add-child-success {
  i {
    &.qi-n-check {
      color: $success;
      font-size: 100px;
    }

    &.qi-c-info-1 {
      color: $qurrah-light-blue;
      font-weight: 700;
      padding: 0 5px;
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    div {
      padding: 30px;

      &:first-child {
        body[dir='ltr'] & {
          border-right: 1px solid $gray-600;
        }

        body[dir='rtl'] & {
          border-left: 1px solid $gray-600;
        }

        @media (max-width: $screen-sm) {
          border-right: 0;

          body[dir='rtl'] & {
            border-left: 0;
          }
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }
  }
}
